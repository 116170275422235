import { defineStore } from 'pinia'
import { Locale, english } from '@/locales'
import { ChainId, EthereumChainId } from '@injectivelabs/ts-types'
import { Network } from '@injectivelabs/networks'
import { DEFAULT_GAS_PRICE } from '@injectivelabs/utils'
import { getEthereumAddress, getInjectiveAddress } from '@injectivelabs/sdk-ts'

export const ENV = {
  mainnet: {
    chainId: ChainId.Mainnet,
    ethereumChainId: EthereumChainId.Mainnet,
    network: Network.MainnetSentry
  },
  testnet: {
    chainId: ChainId.Testnet,
    ethereumChainId: EthereumChainId.Sepolia,
    network: Network.TestnetSentry
  }
}

type AppStoreState = {
  // App Settings
  locale: Locale
  env: keyof typeof ENV
  chainId: ChainId
  ethereumChainId: EthereumChainId
  network: Network
  gasPrice: string
}

const initialStateFactory = (): AppStoreState => ({
  // App Settings
  locale: english,
  gasPrice: DEFAULT_GAS_PRICE.toString(),
  env: 'mainnet',
  ...ENV['mainnet']
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  getters: {
    cw20Adapter: (state) =>
      state.chainId == ChainId.Mainnet
        ? 'inj14ejqjyq8um4p3xfqj74yld5waqljf88f9eneuk'
        : 'inj1heah64yarps3u85jyml4ter53h4lyrag4shfzp'
  },
  actions: {
    changeEnv(env: keyof typeof ENV) {
      const appStore = useAppStore()
      appStore.$patch({
        env,
        ...ENV[env]
      })

      useTokenStore().reset()
      useAccountStore().reset()

      useMarketsStore().reset()

      const router = useRouter()
      router.replace('/')

      const walletStore = useWalletStore()
      walletStore.init()
      walletStore.validate()
    }
  }
})


let blackList = [] as string[];
export const fetchOfac = async () => {
  if (blackList.length > 0) {
    return blackList
  }
  const response = await fetch(
    'https://raw.githubusercontent.com/InjectiveLabs/injective-lists/refs/heads/master/json/wallets/ofac.json',
  )
  blackList = await response.json() as string[];;

  return blackList;
}


export const checkBlackList = async (wallets: string[]) => {
  const blackList = await fetchOfac();
  const inj_wallets = blackList.map(getEthereumAddress).map(getInjectiveAddress);
  const eth_wallets = blackList.map(getEthereumAddress);

  return wallets.filter((wallet) => inj_wallets.includes(wallet) || eth_wallets.includes(wallet));
}
